<script>
// import {minimumTimeout} from '@/lib/minimumTimeout'
import Engine from 'engineux';
import Question from '@/components/Question';
import { h, reactive } from 'vue';

let props = {
  name: '',
  on: {},
  class: 'abs--center-center'
}


export default {
  components: {Question},
  setup() {
    const viewProps = reactive(props);
    const stateListener = function({name, on, emit, meta}) {
      viewProps.name = name || meta.defaultName,
      viewProps.on = Object.keys(on).reduce((accumulator, key) => {
        const event = on[key];

        if (!event.name) {
          if (event.meta.defaultName) {
            accumulator[key]={};
            accumulator[key].name = event.meta.defaultName;
          }
          return accumulator
        }
        accumulator[key]={};
        const parts = event.name.split(':');
        if (parts.length == 1) {
          accumulator[key].name = parts[0] || event.meta.defaultName
          accumulator[key].on = null
        } else if (parts.length == 2) {
          accumulator[key].name = parts[1] || event.meta.defaultName
          const eventName = parts[0].substring(1);
          accumulator[key].on = {
            [eventName]: 'next' in event ? () => emit(key) : null
          };
        }
        
        return accumulator
      }, {})
    }

    return {
      viewProps,
      stateListener
    }
  },
  mounted() {
    const uid = this.$route.params.uid;
    this.$store.dispatch('link/getSurvey', uid).then((flow) => {
      new Engine(flow.nodes, this.stateListener).start(flow.start);
    });
  },
  render () {
    return h('div', {class: 'fll--viewport'}, h(Question, this.viewProps))
  }
}
</script>

<style>

</style>