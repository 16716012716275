<template>
  <div>
    <h3>
      {{name}}
    </h3>
    <ul class="rst--list gtr--md ofst--top-md">
      <li :key="key" v-for="(event, key) in on">
        <button v-on="event.on">{{event.name}}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    on: Object
  }
}
</script>

<style>

</style>